.headerSticky {
  position: -webkit-sticky;
  position: sticky;
  top: 133px;
  background-color: white;
  z-index: 79;
  @include media-breakpoint-up(md){
    font-size: 100%;
    top: 108px;
  }
}


.firstHeaderStickyToC{
  position: -webkit-sticky;
  position: sticky;

  top: 0px;
  background-color: white;
  z-index: 80;
  @include media-breakpoint-up(md){
    top: 60px;
    font-size: 100%;
  }
}



.firstHeaderSticky{
  position: -webkit-sticky;
  position: sticky;
  padding-top:15px;
  top: 55px;
  background-color: white;
  z-index: 80;
}

.beforefirstHeaderSticky{
  text-align:center;
  position: -webkit-sticky;
  position: sticky;
  padding-top:0px;
  top: 0px;
  background-color: white;
  z-index: 81;
}

.ToC{
padding:2px;
@include media-breakpoint-up(md){
  padding-top:65px;
}

}

.TOC{
  line-height:1;
  padding: 5px 2px;
  clear:right;
  a {
    padding-top:2px;
    padding-bottom:2px;
  };
  small{

    padding-right:20px;
  };
  .pointerCursor{
    zoom:0.9;
    padding:2px
  }
}

html {
  font-size: 81.25%;
  @include media-breakpoint-up(md){
    font-size: 100%;
  }
}

body {
  background:none transparent;
  
}

.hero{
  display:none
}

@media (max-width: 800px) {
  .container {
    padding: 7px
  }

  .result-item{
    padding:3px 5px  !important;
  }



  .shopping-cart {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .item {
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
  }
  .image img {
    width: 50%;
  }
  .image,
  .quantity,
  .description {
    width: 100%;
    text-align: center;
    margin: 6px 0;
  }
  .buttons {
    margin-right: 20px;
  }
}

.total-price {
  width: 83px;
  padding-top: 27px;
  text-align: center;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
}
.quantity {
  padding-top: 20px;
  margin-right: 60px;
}
.quantity input {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 32px;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
}


.minus-btn img {
  margin-bottom: 3px;
}
.plus-btn img {
  margin-top: 2px;
}

button:focus,
input:focus {
  outline:0;
}

.is-active {
  animation-name: animate;
  animation-duration: .8s;
  animation-iteration-count: 1;
  animation-timing-function: steps(28);
  animation-fill-mode: forwards;
}

@keyframes animate {
  0%   { background-position: left;  }
  50%  { background-position: right; }
  100% { background-position: right; }
}

.delete-btn,
.like-btn {
  display: inline-block;
  Cursor: pointer;
}
.delete-btn {
  width: 18px;
  height: 17px;
  background: url("https://designmodo.com/demo/shopping-cart/delete-icn.svg") no-repeat center;
}

.like-btn {
  position: absolute;
  top: 9px;
  left: 15px;
  width: 60px;
  height: 60px;
  background-size: 2900%;
  background-repeat: no-repeat;
}
.item {
  padding: 20px 30px;
  height: 120px;
  display: flex;
}

.item:nth-child(3) {
  border-top:  1px solid #E1E8EE;
  border-bottom:  1px solid #E1E8EE;
}

.circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

Badge {
  cursor: pointer;
}


.menuSelector{

  max-width:100vw;
  white-space:nowrap;
  color:black;
  background-color: lightgray;
  margin:-7px;
  div{padding-bottom: 7px;}
}
.labelWithHover{
  display:inline;
  padding:5px 10px;
  &:hover {
    text-decoration-line: underline;
    text-decoration-style: double;
  }
  cursor: pointer;

}

.labelWithHover.selectedLabel{
  text-decoration-line: underline;
  text-decoration-style: double;
}

.bottomLeftFloat2{
  background:lightgray;
  position:fixed;
  right:0;
  padding-top:5px;
  height:50px;
  bottom:0;
  z-index: 10000000001;
  width:100vw;
  .col{
    text-align:center
  }
  svg{
    padding-bottom:5px;
  }
}

.bottomLeftFloat{

  position:fixed;
  right:0;
  bottom:0;
  z-index: 1000000000;


  width:100vw;





  @include media-breakpoint-up(md){
    max-width:500px;
    min-width:350px;
  }


}

.bottomLeftFloatclosed{
  height:0;


}
.bottomLeftFloatopen{
  height:100%;

}

.sticky{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.sticky-responsive{
  @include media-breakpoint-up(md){
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }

}

body {
  font-family: Futura, Futura-Medium, "Futura Medium", "Century Gothic", CenturyGothic, "Apple Gothic", AppleGothic, "URW Gothic L", "Avant Garde", sans-serif;
}

.dropdown-menu-right {
  right: 0px;
  left: auto;
}


.checkboxContainer{
  transform: scale(0.8, 0.8);
  right:10px;
  @include media-breakpoint-up(md){

    transform: scale(1, 1);
  }
}
/*.checkboxContainer:before{
  content:"The top 20 restaurants are shown";
  color:$cherry;
}
.checkboxContainer:after{
  content:"When clicked, the restaurants update as you zoom";
  color:$cherry;
}*/

#dimScreen {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 0;
}

.map-popup {
  background-color: #fff;
  position: fixed;
  padding: 0.5rem;
  margin: auto;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 50;
  color: $black;
  display: flex;
  max-width: 400px;
  border: 1px solid $cherry;

  @include media-breakpoint-up(md) {
    padding: 1rem;
  }
}

.map-popup__close {
  fill: #fff;
  background-color: $cherry;
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  svg {
    display: block;
  }
}

.map-popup__logo {
  flex: 0 0 80px;
  margin-right: 1rem;
}

.map-popup__content {
  font-size: 0.875rem;
  p {
    margin-bottom: 0.5rem;
  }
}

// Hacking Google Maps popover
.gm-style .gm-style-iw-t {
  display: none;
}

.above-component {
  position: relative;
  z-index: 150;
  padding: 2rem 0;
}

.above-component__cities {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.above-component__city {
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: all 0.25s ease;

  &::before {
    content: "· ";
  }

  &:not(:last-child) {
    padding-right: 0.5rem;
  }

  &:hover {
    border-bottom-color: $cherry;
    color: $cherry;
  }
}

.above-above-component {
  position: relative;
  z-index: 200;
}

.app-container {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
}
.hidden {
  display: none;
}
.main {
  margin-bottom: auto;
  padding-bottom: 2rem;
}

.hero {
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 150;
  background-color: #212121;
  padding: 0.5rem 0 0 0;
  color: #ffffff;
}

.navbar-logo {
  width: 80px;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

hr {
  margin: 5px 0px 0px 0px;
}

.center {
  text-align: center;
}

/* styling for header */
.logo-container {
  width: 50%;
}

.app-logo {
  margin-left: 0px !important;
  margin-top: 3px;
}

.search-container {
  display: flex;
  margin: 0 auto;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    max-width: 70%;
  }
}

.autocomplete-main {
  position: relative;
  flex: 1 1;
  min-width: 0;
}

.input-icon {
  position: absolute;
  top: calc(50% - 8px);
  cursor: pointer;
  padding-left: 12px;
  left: 0;
}

.autocomplete-dropdown-container {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 0.9rem;
  z-index: 3;
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 375px;
  overflow-y: auto;
  .suggestion-item {
    background-color: #ffffff;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
  }
  .suggestion-item-active {
    background-color:#ced4da;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
  }
  .suggestions-label {
    margin: 0.25rem 0.5rem;
  }
}

.location-search-input {
  outline: none;
  width: 100%;
  height: 42px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #fafafa;
  font-size: 0.9rem;
  outline: none;
  padding-left: 32px;
  padding-right: 32px;
}

.search-dropdown {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 0.9rem;
  z-index: 3;
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 375px;
  overflow-y: auto;
}

.search-dropdown__item {
  padding: 0.25rem 0.5rem;
  &:hover {
    background-color: $gray-400;
    cursor: pointer;
  }

  &.is-focused {
    background-color: $gray-400;
  }
}

.search-dropdown__content {
  padding: 0.25rem 0.5rem;
}

.search-container__item {
  margin-bottom: 0rem;
  color: $gray-900;
  .search-container__btn {
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 2rem;
    color: #ffffff;
    min-height: auto;
    padding: 0.5rem 1rem;
    &:focus {
      background-color: transparent;
    }
    @media (hover: hover) {
      &.active,
      &:hover {
        background-color: white;
        color: $black;
      }
    }

    & span:last-child {
      border-color: $black;
    }
  }

  .reactive-field {
    @include media-breakpoint-down(md) {
      input {
        height: 32px;
      }
    }
  }

  .location-search-input {
    @include media-breakpoint-down(md) {
        height: 32px;
    }
  }
}

.search-bar {
  border-radius: 3px;
}

.revenue-label {
  font-size: 13px;
  color: #cdcdcd;
  margin-left: 2px;
}

.revenue-radio {
}

.datePicker {
  color: #000;
}

.list-item {
  font-size: 13px;
  color: #cdcdcd;
  margin-left: 2px;
}


.blue {
  height: 1px;
}
/* styling for Result Container */
.row {
  margin: 0px -7px  !important;
}
.search-page {



  .result-list-info {
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .result-item {

    background-color: $gray-100;
    border-width: 1px;
    border-color: $gray-400;
    border-radius: $border-radius;
    padding: 1rem;
    color: $gray-900;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.25;
    flex-direction: column;
    margin-bottom: 0.25rem;
    @include media-breakpoint-up(md) {
      border-width: 0 0 1px 0;
      padding: 1rem;
      border-radius: 0;
      margin-bottom: 0;
      &:nth-child(odd) {
        background-color: white;
      }
      &:hover {
        background-color: inherit;
      }
    }

    &.is-collapsed {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .result-item__head {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .result-item__title {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0;
    margin-right: 0.5rem;
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }

    &.is-collapsed {
      font-weight: normal;
    }
  }

  .result-item__count {
    white-space: nowrap;
    text-align: right;
  }

  .result-item__save {
    font-size: 0.75rem;
    padding: 0.05rem 0.25rem;
  }

  .result-item__count-unit {
    margin-right: 0.25rem;
    @include media-breakpoint-up(md) {
      margin-right: 0;
    }
  }

  .result-item__count-value {
    font-weight: 500;
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }

  .result-item__rating {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    background-color: transparent;
    color: 'black';
    font-size: 0.75rem;
    margin-right: 0.5rem;
  }

  .result-item__body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    &.is-collapsed {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;

      .result-item__head {
        margin-bottom: 1rem;
      }

      .star-raiting {
        margin-right: 0.5rem;
      }

      .result-item__subtitle {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include media-breakpoint-down(sm) {
          max-width: 180px;
        }
      }

      .result-item__info {
        padding-top: 0;
      }

      .result-item__flag {
        margin-right: 0.5rem;
        margin-top: -4px;
      }

      .result-item__count {
        margin-left: auto;
      }
    }
  }

  .result-item__icon {
    width: 1.5rem;
  }

  .result-item__place {
    @include media-breakpoint-up(md) {
      flex: 0 0 30%;
      padding-left: 0.5rem;
    }
  }

  .result-item__place-name {
    font-weight: 600;
    font-size: 0.875rem;
    @include media-breakpoint-up(md) {
      font-size: 1rem;
      text-align: right;
    }
  }

  .result-item__place-city {
    font-weight: 500;
    font-style: italic;
    font-size: 0.875rem;
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  .reactive-field__icon {
    width: 1rem;
    margin-right: 0.5rem;
    margin-top: -15px;
  }

  .result-item__flag {
    width: 1rem;
    margin-right: 0.5rem;
  }

  .cancel-icon {
    margin-top: -10px;
  }

  .result-item__subtitle {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 500;
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(md) {
      flex: 0 0 70%;
      font-size: 1rem;
      padding-right: 0.5rem;
      margin-bottom: 0;
    }
  }

  .result-item__specs {
    display: flex;
  }

  .result-item__spec {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .result-item__spec-label {
    text-align: center;
    font-weight: 500;
    font-size: 0.75rem;
  }

  .result-item__spec-value {
    font-weight: 500;
    font-size: 1rem;
  }

  .result-item__spec-accent {
  }

  .result-item__raiting {
    margin-right: auto;
    padding: 0.4rem 0;
    @include media-breakpoint-up(md) {
      margin-right: 1rem;
    }
  }

  .result-item__footer {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }

  .result-item__info {
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
  }

  .result-item__meta {
    display: flex;
    font-size: 0.875rem;
    flex: 0 0 100%;
    margin-bottom: 0.5rem;
    justify-content: space-between;
    @include media-breakpoint-up(md) {
      flex-direction: column;
      justify-content: flex-start;
      flex: 1 1 20%;
      margin-left: auto;
      margin-bottom: 0;
      order: 1;
      text-align: right;
    }
  }

  .result-item__meta-link {
    margin-right: 0.5rem;
    @include media-breakpoint-up(md) {
      margin-right: 0;
    }
  }

  .result-item__meta-date {
    white-space: nowrap;
  }

  .result-item__menu {
    font-size: 0.875rem;
  }

  .tile-loader {
    background-color: $gray-100;
    border: 1px solid $gray-400;
    border-radius: $border-radius;
    margin-bottom: 1rem;
    padding: 1rem;
    color: $gray-900;
    &:hover {
      background-color: inherit;
    }
  }

  .search-chart {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }



  .testPic {
    .result-item-outer {
      @include media-breakpoint-up(md) {
        zoom:0.8;
      }
    }
    .front__face-photo {
      position: relative;
      top: -130px;
      height: 150px;
      width: 120px;
      margin: 0 20px;

      background-size: contain !important;
      backface-visibility: hidden !important;
      overflow: hidden;
      z-index: 3;
    }

    .front__bkg-photo {
      position: relative;
      height: 150px;
background: url("https://cdn.shopify.com/s/files/1/0020/3164/8827/products/2017_Rombauer_Chardonnay.png?v=1564684113") no-repeat;
      background-size: cover !important;
      backface-visibility: hidden !important;
      overflow: hidden;
      z-index: 2;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    .result-item-outer{
      vertical-align:top;
      position: relative;
        display: inline-block;

      background-color: $gray-100;
      border-width: 1px;
      border-color: $gray-400;
      border-radius: $border-radius;
      padding: 2px;
      color: $gray-900;
      display: flex;
      flex-wrap: wrap;
      line-height: 1.25;
      flex-direction: column;
      margin-bottom: 0.25rem;
      @include media-breakpoint-up(md) {
        border-width: 0 0 1px 0;
        padding: 1rem;
        border-radius: 0;
        margin-bottom: 0;

        &:hover {
          background-color: inherit;
        }
      }

      &.is-collapsed {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
    .result-item {
      padding: 1rem;
      display: flex;
      flex-wrap: wrap;
      line-height: 1.25;
      flex-direction: column;



      &.is-collapsed {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }
}

.result-image {
  transition-duration: 1s;
  overflow: hidden;
  height: 325px !important;
  width: 250px !important;
  margin-bottom: 7px;
}

.voters {
  font-size: 11px;
}

.details {
  font-size: 11px;
  color: #cdcdcd;
  text-align: center;
}

.sub-title {
  font-size: 13px;
  text-align: center;
}

.time {
}

.rating-time-score-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.revenue-lang-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.revenue-data {
  font-size: 13px;
  text-align: center;
}

.result-container {
}

.result-container__head {
  padding: .5rem 1rem;
  background-color: $gray-100;
  border-radius: $border-radius;
  border: 1px solid $gray-400;
  margin-bottom: 1rem;
}

.result-container__head-btn {
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.result-container__map {
  padding: .5rem 1rem;
  background-color: $gray-100;
  border-radius: $border-radius;
  border: 1px solid $gray-400;
  margin-bottom: 1rem;
}

.result-container__map-meta {
  display: flex;
  color: $gray-900;
  padding-top: .5rem 1rem;
}

.result-container__featured {
  display: flex;
  color: $gray-900;
  padding: .5rem 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  font-size: 1rem;
  flex-direction: column;
}

.result-container__featured-name {
  font-weight: 600;
  font-size: 1.25rem;
  margin-right: 0.5rem;
  @include media-breakpoint-up(md) {
    margin-right: 0;
  }
}

.result-container__featured-address,
.result-container__featured-price,
.result-container__featured-rating,
.result-container__featured-info {
  margin-right: 0.5rem;
  @include media-breakpoint-up(md) {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

.result-container__featured-buckets {
  display: flex;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.revenue-time-container {
  display: flex;
  flex-direction: row;
}

.language-data {
  text-align: center;
}
.pointerCursor{
  cursor: pointer;
}

.filterOptions {
  .col{cursor: pointer;}
}

.result-container-optional {
  width: 85%;
}


.toggle-button {
  display:none;
  position: fixed;
  font-size: 15px;
  height: 35px;
  z-index: 201;
  opacity: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  left: 1rem;
  bottom: 4rem;
  cursor: pointer;

  &.menu-button {
    left: 10rem;
  }
  &.menu-button2 {
    box-shadow:none;
    top:     5vh;
    left:95%;
    color:black;

  }
}

.extra-description {
  width: 100%;
}

.result-stats {
  font-size: 14px;
  margin-left: 3px;
  margin-top: 5px;
}

.result-title {
  display: none;
}

.result-card-header {
  flex-direction: column;
}

.result-card-header > div {
  justify-content: center;
  margin-top: 5px;
}

/* styling for overlay effect */

.overlay-title {
  margin-top: 7px;
}

.overlay-description {
  position: absolute;
  color: #fff !important;
  font-size: 15px !important;
  margin-bottom: 50px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-bottom: 155px;
  padding: 8px;
  font-style: italic;
}

.overlay-info {
  position: absolute;
  background-color: #111111;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  margin-bottom: 7px;
}

.ih-item {
  position: relative;
  transition: all 0.35s ease-in-out;
  color: black;
}

.ih-item a:hover {
  text-decoration: none;
}

.ih-item.square .info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  backface-visibility: hidden;
}

.ih-item.square.effect6 {
  overflow: hidden;
}

.ih-item.square.effect6.colored .info {
  background: #1a4a72;
  background: rgba(26, 74, 114, 0.6);
}

.ih-item.square.effect6.colored .info h3 {
  background: rgba(12, 34, 52, 0.6);
}

.ih-item.square.effect6 .img,
.ih-item.square.effect6 .info {
  transition: all 0.35s ease-in-out;
}

.ih-item.square.effect6 .info {
  background: #333;
  background: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  opacity: 0;
}

.ih-item.square.effect6 .info h3 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-size: 17px;
  padding: 10px;
  background: #111;
  transition: all 0.35s ease-in-out;
}

.ih-item.square.effect6 a:hover .img {
  transform: scale(1.05);
}

.ih-item.square.effect6 a:hover .info {
  visibility: visible;
  opacity: 1;
}

.ih-item.square.effect6.top_to_bottom .info h3 {
  transform: translateY(-100%);
}

.ih-item.square.effect6.top_to_bottom a:hover .info h3 {
  transform: translateY(0);
}

/* styling for scrollbar */

// ::-webkit-scrollbar {
//   width: 13px;
//   height: 13px;
// }

// ::-webkit-scrollbar:hover {
//   height: 18px;
// }

// ::-webkit-scrollbar-track-piece {
//   background-color: #151716;
// }

// ::-webkit-scrollbar-thumb:vertical {
//   height: 50px;
//   background: -webkit-gradient(
//     linear,
//     left top,
//     right top,
//     color-stop(0, #4d4d4d),
//     color-stop(100%, #333)
//   );
//   border: 1px solid #0d0d0d;
//   border-top: 1px solid #666;
//   border-left: 1px solid #666;
// }

// ::-webkit-scrollbar-thumb:horizontal {
//   width: 50px;
//   background: -webkit-gradient(
//     linear,
//     left top,
//     left bottom,
//     color-stop(0, #4d4d4d),
//     color-stop(100%, #333)
//   );
//   border: 1px solid #1f1f1f;
//   border-top: 1px solid #666;
//   border-left: 1px solid #666;
// }

/* Responsive styling */
@media (max-width: 768px) {
  .toggle-button {
    display: block;
    position: fixed;
    z-index: 201;
    left: 1rem;
    bottom: 4rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  }

  .left-bar-optional {
    width: 85%;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #212121;
    box-shadow: none;
    border-radius: 5px;
    color: #fff;
    min-width: 200px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px 10px 10px 10px;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 4px;
  }

  .search-container {
    width: 100%;
  }

  .logo-container {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }

  .result-container {
    width: 100%;
  }

  .left-bar,
  .result-container-optional {
    display: none;
  }

  .app-logo {
    margin-top: 3px;
    width: 207px;
    height: 30px;
  }
}

.box {
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-radius: $border-radius;
}

// Added styles
.pricing-table {
  padding: 2rem 0 0;
  max-width: 600px;
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.price {
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-radius: $border-radius;
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
  list-style-type: none;
  flex: 1;
  transition: $transition-base;
  &:not(:last-child) {
    margin-right: 1rem;
  }
  &:hover {
    box-shadow: $box-shadow;
  }
}

.price__title {
  font-size: 2rem;
  border-bottom: none;
}

.price__value {
  font-size: 1.5rem;
  color: $primary;
}

.price__item {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $gray-400;
}

.reactive-field {
  position: relative;
  flex: 1 1;
  min-width: 0;
  width: 100%;
  margin-bottom: 0.5rem;
  .search-icon {
    position: absolute;
  }

  .input-group & {
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
  }
}

.aside {

  color: $gray-900;
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-radius: $border-radius;
  padding: 1rem;
  position: fixed;
  width: 100vw;
  height: 100vh;
  transform: translateX(-1000%);
  top: 0;
  left: 0;
  transform: $border-radius;
  z-index: 200;
  overflow-y: auto;
  &.is-active {
    transform: translateX(0);
  }

  @include media-breakpoint-up(md) {

    position: static;
    //transform: translateX(0);
    width: auto;
    height: 100vh;
    overflow-y: scroll;
  }

  .filter-heading {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: $gray-600;
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  .filter-item {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray-400;
  }

  .filter-button {
    background-color: transparent;
    border: 1px solid $cherry;
    border-radius: 2rem;
    color: $cherry;
    min-height: auto;
    padding: 0.2rem 0.7rem;
    font-size: 14px;

    &:focus {
      background-color: transparent;
    }
    @media (hover: hover) {
      &.active,
      &:hover {
        background-color: $cherry;
        color: #ffffff;

      }
    }

    &.active {
      background-color: $cherry;
      color: #ffffff;
    }

    @include media-breakpoint-up(md) {
      font-size: inherit;
      padding: 0.5rem 1rem;
    }
  }



  .tag-button {
    background-color: transparent;
    border: 1px solid $cherry;
    border-radius: 2rem;
    color: $cherry;
    min-height: auto;
    padding: 0.5rem 1rem;
    &.active {
      color: $white;
    }
    &:focus {
      background-color: transparent;
    }
    @media (hover: hover) {
      &:hover {
        background-color: $cherry;
        color: #ffffff;
      }
    }
  }
  .labelsForRangeSliderLeft {
    display:inline;
    float:left;
    width: 0%
  }
  .labelsForRangeSliderRight {
    display:inline;
      float:left;
    width: 0%
  }
  .review-filter {

    width:100%;
    display:inline;
    float:left;



  }
}

.filterPopupWhite{
  background:white;

}


.filterPopup{
  svg {
      zoom:1 !important;
  };
  position:fixed;
  width:100%;
  top:0;
  left:0;

  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  z-index:300000000 !important;

}
.col-6, .col-12{
  transition: all 200ms ease;
}

.filterOptions{
  .col{
    text-align: center;
  };

  svg {
      zoom:0.7;
  }

}


.filterOptions{
  margin:auto;
}
.filterPopup .filterOptions{
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  padding:10px;
}

.tocPopup .filterOptions, #addShadowToc{
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  padding:10px;
}

.openedPopup{
  padding:10px;
  height:400px;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.filterButtonContainer{
  padding:20px 0px;
}
.closedPopup{
  height:0px;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.dimmerLayer{
  background-color:black;
  left:0;
  opacity:0.5;
  position:fixed;
  width:100%;
  height:100%;
  z-index:20000000;

}

.dimmerLayer2{
  background-color:black;
  left:0;
  opacity:0.5;
  position:fixed;
  width:100%;
  height:100%;
  z-index:0;

}


.filter-heading{
  clear:left;
}
.hideForLarge{
  @include media-breakpoint-up(md) {
      display:none;
  }

}
.aside2 {
    color: $gray-900;
    background-color: $gray-100;
    position: fixed;
    width: 100vw;
    height: 100vh;
    transition: 0.2s;
    transform: translateY(-300%);
    height:70vh;
    top: 0px;
    left: 0;
    transform: $border-radius;
    z-index: 10000;
    overflow-y: auto;
    &.is-active {
      transform: translateY(0vw);
    }


    @include media-breakpoint-up(md) {
      position: sticky;
      transform: translateY(0);
      width: auto;
      height: 100vh;
      z-index: 10;
      overflow-y: scroll;
    }

    .filter-heading {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0.5rem;
      color: $gray-600;
      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }

    .filter-item {
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $gray-400;
    }

    .filter-button {
      background-color: transparent;
      border: 1px solid $cherry;
      border-radius: 2rem;
      color: $cherry;
      min-height: auto;
      padding: 0.2rem 0.7rem;
      font-size: 12px;
      &:focus {
        background-color: transparent;
      }
      @media (hover: hover) {
        &.active,
        &:hover {
          background-color: $cherry;
          color: #ffffff;
        }
      }

      &.active {
        background-color: $cherry;
        color: #ffffff;
      }

      @include media-breakpoint-up(md) {
        font-size: inherit;
        padding: 0.5rem 1rem;
      }
    }

    .tag-button {
      background-color: transparent;
      border: 1px solid $cherry;
      border-radius: 2rem;
      color: $cherry;
      min-height: auto;
      padding: 0.5rem 1rem;
      &.active {
        color: $white;
      }
      &:focus {
        background-color: transparent;
      }
      @media (hover: hover) {
        &:hover {
          background-color: $cherry;
          color: #ffffff;
        }
      }
    }

    .review-filter {
      .rheostat-horizontal .rheostat-handle {

        padding: 12px;
      }

    }
  }


.popup-content {
  padding: 1rem !important;
  background-color: $gray-100 !important;
  border-radius: $border-radius;
  border: 1px solid $gray-400 !important;
  z-index: 200 !important;
}

// Inspiration https://codepen.io/vaughndtaylor/pen/vZaYLX

.star-raiting {
  display: flex;
}

.star-raiting__label {
  font-weight: 500;
  font-size: 1rem;
  margin-right: 0.25rem;
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
}

.star-raiting__stars {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.star-raiting__stars-inactive {
  display: inline-block;
  position: relative;
  height: 9px;
  width: 50px;
  background-image: url("data:image/svg+xml,%3Csvg width='98' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0l2.443 6.876H18l-5.35 4.038L14.562 18 9 13.751 3.439 18l1.913-7.086L0 6.876h6.557zM29 0l2.443 6.876H38l-5.35 4.038L34.562 18 29 13.751 23.439 18l1.913-7.086L20 6.876h6.557zM49 0l2.443 6.876H58l-5.35 4.038L54.562 18 49 13.751 43.439 18l1.913-7.086L40 6.876h6.557zM69 0l2.443 6.876H78l-5.35 4.038L74.562 18 69 13.751 63.439 18l1.913-7.086L60 6.876h6.557zM89 0l2.443 6.876H98l-5.35 4.038L94.562 18 89 13.751 83.439 18l1.913-7.086L80 6.876h6.557z' fill='%23ccc'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
}

.star-raiting__stars-active {
  position: absolute;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='98' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0l2.443 6.876H18l-5.35 4.038L14.562 18 9 13.751 3.439 18l1.913-7.086L0 6.876h6.557zM29 0l2.443 6.876H38l-5.35 4.038L34.562 18 29 13.751 23.439 18l1.913-7.086L20 6.876h6.557zM49 0l2.443 6.876H58l-5.35 4.038L54.562 18 49 13.751 43.439 18l1.913-7.086L40 6.876h6.557zM69 0l2.443 6.876H78l-5.35 4.038L74.562 18 69 13.751 63.439 18l1.913-7.086L60 6.876h6.557zM89 0l2.443 6.876H98l-5.35 4.038L94.562 18 89 13.751 83.439 18l1.913-7.086L80 6.876h6.557z' fill='%23212121'/%3E%3C/svg%3E");
  background-size: cover;
}

.star-raiting__stars-hint {
  font-size: 0.5rem;
}

.bottomMenu{
  position:fixed;
  left:75%;
  bottom:0px;
  width:25%;
  z-index:100000000;
}
@media screen and (max-width: 600px) {

  .bottomMenu{
    margin:auto;
    left:0px;
    bottom:0px;
    width:100%
  }

  .rheostat-handle {
      padding: 12px !important;
  }

}
